import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { todoActions } from '../../../actions'

import TodoAdd from './todoAdd/TodoAdd'

class TodoAddContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			todo: {},
		}
	}

	componentWillMount(){
		this.setEmptyTodo()
	}
	
	emptyTodo(){
		let type = ""
		let country_id =  ""
		let region_id = ""
		let city_id = ""
		if( this.state.todo.type) type = this.state.todo.type
		if( this.state.todo.country_id) country_id = this.state.todo.country_id
		if( this.state.todo.region_id) region_id = this.state.todo.region_id
		if( this.state.todo.city_id) city_id = this.state.todo.city_id
		
		const emptyTodo = { 
			name: "",
			description: "",
			type: type,
			country_id: country_id,
			region_id: region_id,
			city_id: city_id,
		}

		return emptyTodo;
	}

	setEmptyTodo(){
		let newState = update( this.state, {
			todo: {$set: this.emptyTodo()}
		});		
		this.setState( newState );
	}

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
		if(name === "type" 
			|| name === "country_id" 
			|| name === "region_id"
			|| name === "city_id" ){
			this.props.onFilterChange( name, value)
		}
		let newState = update( this.state, {
			todo: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleSubmit = (event) => {
		const {dispatch,storeAuth} = this.props

		dispatch( todoActions.add( storeAuth.user, this.state.todo) )
      	this.setEmptyTodo()
	}

	render(){
		const {todo} = this.state
		
		return  <TodoAdd {...this.props} todo={todo}
			handleChange={this.handleChange}
			handleSubmit={this.handleSubmit} />
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTodo: state.todo, 
		storeAuth: state.auth,
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoAddContainer)
