import React, {Component} from 'react';

import TodoContainer from '../../todoContainer/TodoContainer'

import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid';


const styles = theme => ({});


class TodoList extends Component {    	
	
	render() {
		const {list, ...other} = this.props
		if( !list ) return false 

		return <Grid container spacing={0}>
      {list.map( t => <Grid item sm={4} xs={12} key={t.id} ><TodoContainer todo={t} {...other} /></Grid> )}
    </Grid>

	}
}

export default withStyles(styles)(TodoList);