import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles'

import {todoType} from '../../../../constants';

const styles = theme => ({
  	textField: {
   		margin: theme.spacing.unit * 2
  	}, 
  	select: {
   		margin: theme.spacing.unit * 2
  	},  	
});

const TodoAdd = (props) => {
	const {todo,handleChange,handleSubmit,countryList,regionList,cityList,classes} = props
	if (!todo || !countryList || !regionList || !cityList ) return false

	return <div>
		<div>
			<FormControl className={classes.textField}>
          		<InputLabel shrink >
	            	Country
    	      	</InputLabel>		
				<Select
					label="Country"
			    	name="country_id"
			    	placeholder="Country" 
				    onChange={handleChange} 
			    	value={todo.country_id} > 
				    <MenuItem value="">
	              		<em>None</em>
	            	</MenuItem>
	            	{
	            		countryList.map( t => <MenuItem value={t.id} key={t.id} >
	            			<em>{t.name}</em></MenuItem>            		
	            	)}
				</Select>
			</FormControl>
			<FormControl className={classes.textField}>
          		<InputLabel shrink >
	            	Region
    	      	</InputLabel>		
				<Select
					label="Region"
			    	name="region_id"
			    	placeholder="Region" 
				    onChange={handleChange} 
			    	value={todo.region_id} > 
				    <MenuItem value="">
	              		<em>None</em>
	            	</MenuItem>
	            	{
	            		regionList.map( t => <MenuItem value={t.id} key={t.id}>
	            			<em>{t.name}</em></MenuItem>            		
	            	)}
				</Select>
			</FormControl>
			<FormControl className={classes.textField}>
          		<InputLabel shrink >
	            	City
    	      	</InputLabel>		
				<Select
					label="City"
			    	name="city_id"
			    	placeholder="City" 
				    onChange={handleChange} 
			    	value={todo.city_id} > 
				    <MenuItem value="">
	              		<em>None</em>
	            	</MenuItem>
	            	{
	            		cityList.map( t => <MenuItem value={t.id} key={t.id}>
	            			<em>{t.name}</em></MenuItem>            		
	            	)}
				</Select>
			</FormControl>
			<FormControl className={classes.textField}>
          		<InputLabel shrink >
	            	Type
    	      	</InputLabel>		
				<Select
					label="Type"
			    	name="type"
			    	placeholder="Type" 
				    onChange={handleChange} 
			    	value={todo.type} > 
				    <MenuItem value="">
	              		<em>None</em>
	            	</MenuItem>
	            	{
	            		todoType.map( t => <MenuItem value={t.value} key={t.value} >
	            			<em>{t.label}</em></MenuItem>            		
	            	)}
				</Select>
			</FormControl>
			<TextField
				label="Name"
		    	className={classes.textField}
		    	name="name"
		    	placeholder="Name" 
			    onChange={handleChange} 
			    value={todo.name} />

			<TextField
				label="Description"
		    	className={classes.textField}
		    	name="description"
		    	placeholder="Description" 
			    onChange={handleChange} 
			    value={todo.description} />

			<FormControl className={classes.textField}>
				<button className="btn btn-outline-secondary" type="button" 
					onClick={handleSubmit}>Add</button>
			</FormControl>
		</div>		
			
		</div>
}

export default withStyles(styles)(TodoAdd);