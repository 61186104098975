import React, {Component} from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { todoActions } from '../../../actions'
import MenuItem from '@material-ui/core/MenuItem';


class TodoDeleteContainer extends Component {

	handleClick = (event) => {
		const {dispatch,callBack} = this.props

		if(window.confirm('Are you sure you want to eliminate it?')){
        	dispatch( todoActions._delete(this.props.id) )
        	if(callBack){
        		callBack()
        	}

        }

	}

	render(){	
		if( !this.props.id ) return false

		let view = <button type="button"
			className="btn btn-outline-danger" 
			onClick={this.handleClick}>
              <FontAwesomeIcon icon="trash-alt"/> Eliminar
      		</button>
		
		if( this.props.view && this.props.view === "menuItem" ){
			view = <MenuItem onClick={this.handleClick}>Delete</MenuItem>
		}

		return view 
	}
}

const mapStateToProps = (state, ownProps) => {
	return {

	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoDeleteContainer);