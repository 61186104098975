import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Switch, Route, Redirect } from 'react-router-dom';

import Auth from '../modules/Auth';

import { PrivateRoute } from './PrivateRoute';

import LoginContainer from '../components/loginContainer/LoginContainer';

import CoverContainer from '../components/cover/coverContainer/CoverContainer';

import TodoPage from './page/TodoPage';
import 'typeface-roboto'

import { alertActions } from '../actions';
import { history } from '../helpers/history';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt, 
  faGlobeAsia, faGlobe, faCity, faAppleAlt, faUtensils, faTree, faUniversity, faVihara, faGopuram, faArchway, faLeaf, faPaw, faMountain, faAsterisk,
  faBowlingBall, faCheckCircle, faImage, faQuestionCircle} from '@fortawesome/free-solid-svg-icons'

library.add( faTrashAlt, 
  faGlobeAsia, faGlobe, faCity, faAppleAlt, faUtensils, faTree, faUniversity, faVihara, faGopuram, faArchway, faLeaf, faPaw, faMountain, faAsterisk,
  faBowlingBall, faCheckCircle, faImage, faQuestionCircle)

class App extends Component { 

  constructor(props) {
   	super(props);

    	const { dispatch } = this.props;
    	history.listen((location, action) => {
      	// clear alert on location change
       	dispatch(alertActions.clear());
    	});
   }

   render(){
      return <Switch>
          <Route exact path="/" component={CoverContainer} />
          <Route exact path="/t/:todoType" component={CoverContainer} />

          <Route exact path="/login" component={LoginContainer} />
          <Route exact path="/logout" render={ () => {
                Auth.deauthenticateUser()
                return <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />             
              }
            }
          />

			    <PrivateRoute exact path="/admin" component={TodoPage} />
			</Switch>
    }
}

const mapStateToProps = (state, ownProps) => {
  const { authentication } = state;
  return {
    auth: authentication,
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));  