import React from 'react';

import Button from '@material-ui/core/Button';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles'

import TodoEdit from '../todoEdit/TodoEdit'

const styles = theme => ({
 	
});

const TodoEditDialog = (props) => {
	const {todo,handleChange,handleClose,onChangeUpload} = props
	if( !todo ) return false
	return <div>
		<DialogTitle id="scroll-dialog-title">{todo.name}</DialogTitle>
      	<DialogContent>
        	<TodoEdit {...props} todo={todo} 
    	   		handleChange={handleChange} 
    	   		onChangeUpload={onChangeUpload} /> 
      	</DialogContent>
		<DialogActions>
			<Button onClick={handleClose} color="primary">
				Close
			</Button>
		</DialogActions>
	</div>

}

export default withStyles(styles)(TodoEditDialog);