import React, {Component} from 'react';

import { withStyles } from '@material-ui/core/styles'

import Masonry from 'react-masonry-component';

import GridListTileBar from '@material-ui/core/GridListTileBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@material-ui/core/IconButton';


import { todoType} from '../../../../constants';

import {DOMAIN_CONTENT} from '../../../../constants/constants';

const styles = theme => ({

  	gridList: {
   	  width: "100%",
   	  height: "100vh",
  	},
  	icon: {
   	  color: 'rgba(255, 255, 255, 0.54)',
  	},
  	photoContainer: {
  		justifyContent: "center",
  		display: "flex",
  		alignItems: "center",
  		width: "100%",
  		height: "100%",  		
		  backgroundColor: theme.palette.background.default,
  	},
  	photoTodo: {
  		width: '100%'  		
  	},
  	gridItem: {
  		margin: '1px'
  	}
})

const masonryOptions = {
    transitionDuration: 1,
    columnWidth: 1,
    gutter: 1,
};
 
const imagesLoadedOptions = { background: '.my-bg-image-el' }

class PhotoGrid extends Component {    	
	
	render() {
		const {list, baseWidth, iconSize, classes} = this.props
		if( !list ) return false

   	let width = baseWidth;

		const childElements = list.map(function(t){
      	let description = ""
         let descIcon = ""
              
        	let type = ""
        	todoType.filter( type => type.value === t.type ).map( t => {
         	type = t
          	return true
        	})
              

        	let widthNoPhoto = parseInt(baseWidth ,10)
			if( t.done ) {
				width = baseWidth * 2
			}
			if( t.favorite ){
				width = parseInt(baseWidth * 2.5,10)
			}

 			if( t.done ){
				description =	<span>{t.description}</span>
				descIcon = <IconButton className={classes.icon}>
					<FontAwesomeIcon icon={type.icon} color={type.color} />
				</IconButton>
			}
			description = ""

			let noPhoto = <div className={classes.photoContainer} style={{width: widthNoPhoto +"px",height: widthNoPhoto +"px"}}>
				<FontAwesomeIcon icon={type.icon} color='#3B5998' size={iconSize} />
			</div>
			let photo = <div className={classes.photoContainer} style={{width: width +"px"}}>
            <img src={DOMAIN_CONTENT +"content/xs"+ t.photo}             	
					alt={t.title} className={classes.photoTodo} />
         </div>

			return <div key={t.id} className={classes.gridItem}> 
            			{t.photo?photo:noPhoto}
            			<GridListTileBar
	   	           		title={t.name}
   	   	        		subtitle={description} 
                    		actionIcon={descIcon} />
          	</div>
		})

		return  <Masonry
                className={'my-gallery-class'} // default ''
                options={masonryOptions} // default {}
                disableImagesLoaded={false} // default false
                updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
                imagesLoadedOptions={imagesLoadedOptions} // default {}
            >
                {childElements}
            </Masonry>
	}
}

export default withStyles(styles)(PhotoGrid);