import React, {Component} from 'react';

import { connect } from 'react-redux'

import TodoListContainer from '../todo/todoListContainer/TodoListContainer';
import TodoAddContainer from '../todo/todoAddContainer/TodoAddContainer';

import PageFullWidth from '../PageFullWidth';

var accents = require('remove-accents');

class TodoPage extends Component {
	constructor(props) {
		super(props)

		this.state = {
            filter: {},
            search: "",
            countryList: [],
            regionList: [],
            cityList: [],
		}
    }

    componentWillMount(){
  
    }
    componentDidMount() {
        document.title = "旅行 - Hector around the world"
        document.description = "Travel Log, the best way to plan what to visit and keep your memories"
    }
    componentWillReceiveProps( nextProps ){
        const {storeTodo} = nextProps
        if( !storeTodo || !storeTodo.list ) return false
        if( storeTodo && storeTodo.isFetching ) return false

        const list = storeTodo.list
        const countryList = list.filter( todo => todo.type === "country" )
        const regionList = list.filter( todo => todo.type === "region" )
        const cityList = list.filter( todo => todo.type === "city" )

        this.setState({
            countryList: countryList,
            regionList: regionList,
            cityList: cityList,
        })
    }

    onFilterChange = (name, value ) => {
        let filter = Object.assign({},this.state.filter)
        filter[name] = value

        this.setState({filter: filter})
    }

    onSearchChange = ( event ) => {
        const target = event.target
        const value = accents.remove(target.value.toLowerCase())

        this.setState({search: value})
    }


	render(){
        const {countryList,regionList,cityList,filter,search} = this.state
		const main = <div>
            <TodoAddContainer onFilterChange={this.onFilterChange}  
                countryList={countryList} regionList={regionList} cityList={cityList} />
            <TodoListContainer filter={filter} search={search}
                countryList={countryList} regionList={regionList} cityList={cityList} />
        </div>
		
		return <div>
            <PageFullWidth  main={main} onSearchChange={this.onSearchChange} />
        </div>
		
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        storeTodo: state.todo,
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoPage);
