import React, {Component} from 'react';

import { connect } from 'react-redux'

//import PhotoGrid from './photoGrid/PhotoGrid';
import MasonryGrid from './masonryGrid/MasonryGrid';

class PhotoGridContainer extends Component {

	render(){
        const {list, cols, baseWidth, iconSize} = this.props
        return <MasonryGrid list={list} cols={cols} baseWidth={baseWidth} iconSize={iconSize}/>
		//return <PhotoGrid list={list} cols={cols} />
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGridContainer);
