import React, {Component} from 'react';

import { connect } from 'react-redux'

import { todoActions } from '../../../actions'
import Cover from './cover/Cover';

class CoverContainer extends Component {
	constructor(props) {
		super(props)

		this.state = {
            todoList: [],
            todoTypeValue: "",
		}
    }

    componentWillMount(){
        const {dispatch,storeTodo} = this.props
        this.setState({
            todoTypeValue: this.props.match.params.todoType
        })    

        if( !storeTodo || !storeTodo.list ){
            dispatch(todoActions.load())
            return false
        } 
    }

    componentDidMount() {
        document.title = "旅行 - Hector around the world"
        document.description = "Travel Log, the best way to plan what to visit and keep your memories"
    }

    componentWillReceiveProps( nextProps ){
        const {dispatch,storeTodo} = nextProps

        const todoTypeValue = nextProps.match.params.todoType
        if( todoTypeValue !== this.state.todoTypeValue){
            this.setState({
                todoTypeValue: nextProps.match.params.todoType
            })    
        }

        if( !storeTodo || !storeTodo.list ){
            dispatch(todoActions.load())
            return false
        } 

        if( storeTodo && storeTodo.isFetching ) return false

        let list = storeTodo.list
        if( todoTypeValue ){
            list = list.filter( todo => todo.type === todoTypeValue)
        }

        const countryList = list.filter( todo => todo.type === "country" )
        const regionList = list.filter( todo => todo.type === "region" )
        const cityList = list.filter( todo => todo.type === "city" )

        this.setState({
            todoList: list,
            countryList: countryList,
            regionList: regionList,
            cityList: cityList,
        })
    }


	render(){
        const {todoList,todoTypeValue} = this.state
		return <Cover todoList={todoList} todoTypeValue={todoTypeValue}  />		
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        storeTodo: state.todo,
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverContainer);
