
import React, {Component} from 'react';
import { connect } from 'react-redux'

import { todoActions } from '../../../actions'

import TodoList from './todoList/TodoList';

var accents = require('remove-accents');

class TodoListContainer extends Component { 

	constructor(props) {
		super(props);

		this.state = {
			list: [],
		}
	}

	setTodo = ( dispatch, storeTodo ) => {	
		if( storeTodo && storeTodo.isFetching ) return false

		if( !storeTodo 
			|| !storeTodo.list ){
			dispatch( todoActions.load() )

			return false
		} 
		

		this.setState({
			list: storeTodo.list,
		})

		return true
	}

	componentWillMount(){	
		const {dispatch,storeTodo} = this.props

		this.setTodo( dispatch, storeTodo )
  	}

	componentWillReceiveProps( nextProps ){
		const {dispatch,storeTodo} = nextProps
					console.log("receive Props")

		this.setTodo( dispatch, storeTodo )
	}

	render(){
		const { list } = this.state
		if( !list ) return false

		let l = Object.assign([],list)

		Object.keys(this.props.filter).map( key => {
			let value = this.props.filter[key]
			if( value !== ""){
				l = l.filter( todo => todo[key] === value )
			}
			return true
		})

		if( this.props.search && this.props.search !== ""){
			l = l.filter( todo => accents.remove(todo.name.toLowerCase()).includes(this.props.search) )
		}
		

		return <TodoList {...this.props} list={l} />
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		storeTodo: state.todo,		
	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoListContainer);
