import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';

import Spinner from '../../../Spinner'

import {DOMAIN_CONTENT} from '../../../../constants/constants';

import {todoType} from '../../../../constants';

var moment = require('moment');


const styles = theme => ({
  	textField: {
   		margin: theme.spacing.unit,
  	},  
  	button: {
    	margin: theme.spacing.unit,
  	},	
});

const TodoEdit = (props) => {
	const {todo,handleChange,handleBlur,onClickHandleRotate,onChangeUpload,isUploading,countryList,regionList,cityList,classes} = props
	if( !todo ) return false
	let extraField = ""
	let extraField2 = ""
	if( todo.type !== "country" && todo.type !== "region" && todo.type !== "city" ){
		extraField = <Grid item sm={6}>	
		<FormControl className={classes.textField} fullWidth>
          	<InputLabel shrink >
            	City
          	</InputLabel>		
			<Select
				label="City"
				fullWidth
		    	className={classes.select}
		    	name="city_id"
		    	placeholder="City" 
			    onChange={handleChange} 
			    onBlur={handleBlur} 
			    value={todo.city_id} >			    
			    <MenuItem value="">
              		<em>None</em>
            	</MenuItem>
            	{
            		cityList.map( t => <MenuItem value={t.id}>
            			<em>{t.name}</em></MenuItem>            		
            	)}
			</Select>
		</FormControl>
		</Grid>
	}else if( todo.type === "city" || todo.type === "region" ){
		if( todo.type === "city" ){
			extraField = <Grid item sm={6}>	
				<FormControl className={classes.textField} fullWidth>
		          	<InputLabel shrink >
		            	Region
		          	</InputLabel>		
					<Select
						label="Region"
						fullWidth
				    	className={classes.select}
				    	name="region_id"
				    	placeholder="Region" 
					    onChange={handleChange} 
					    onBlur={handleBlur} 
					    value={todo.region_id} >			    
					    <MenuItem value="">
		              		<em>None</em>
		            	</MenuItem>
		            	{
		            		regionList.map( t => <MenuItem value={t.id} key={t.id}>
		            			<em>{t.name}</em></MenuItem>            		
		            	)}
					</Select>
				</FormControl>
				</Grid>
		}
		extraField2 = <Grid item sm={6}>	
		<FormControl className={classes.textField} fullWidth>
          	<InputLabel shrink >
            	Country
          	</InputLabel>		
			<Select
				label="Country"
				fullWidth
		    	className={classes.select}
		    	name="country_id"
		    	placeholder="Country" 
			    onChange={handleChange}
			    onBlur={handleBlur}
			    value={todo.country_id} >			    
			    <MenuItem value="">
              		<em>None</em>
            	</MenuItem>
            	{
            		countryList.map( t => <MenuItem value={t.id} key={t.id}>
            			<em>{t.name}</em></MenuItem>            		
            	)}
			</Select>
		</FormControl>
		</Grid>
	}
	return <Grid container spacing={24}>
		<Grid item sm={6}>
			<TextField
				label="Name"
				fullWidth
		    	className={classes.textField}
		    	name="name"
		    	placeholder="Nombre" 
			    onChange={handleChange} 
			    onBlur={handleBlur} 
			    value={todo.name} />			
		</Grid>
		<Grid item sm={6}>	
		<FormControl className={classes.textField} fullWidth>
          	<InputLabel shrink >
            	Type
          	</InputLabel>		
			<Select
				label="Type"
				fullWidth
		    	className={classes.select}
		    	name="type"
		    	placeholder="Type" 
			    onChange={handleChange}
			    onBlur={handleBlur} 
			    value={todo.type} >			    
			    <MenuItem value="">
              		<em>None</em>
            	</MenuItem>
            	{
            		todoType.map( t => <MenuItem value={t.value} key={t.value}>
            			<em>{t.label}</em></MenuItem>            		
            	)}
			</Select>
		</FormControl>
		</Grid>
		{extraField}{extraField2}
		<Grid item sm={12}>
			<TextField
				label="Description"
				fullWidth
				multiline
		    	className={classes.textField}
		    	name="description"
		    	placeholder="Description" 
			    onChange={handleChange}
			    onBlur={handleBlur} 
			    value={todo.description} />
		</Grid>
		<Grid item sm={6}>
			<FormControlLabel
          		control={
            		<Checkbox
            			checked={(todo.done)?true:false}
              			onChange={handleChange}
              			onBlur={handleBlur} 
              			name="done"
              			color="primary"
            		/>
          		}
          	label="Done"
        	/>
        	<TextField
        		id="datetime-local"
        		label="Date"
        		type="datetime-local"
        		name="done_date"
        		value={moment(todo.done_date).format('YYYY-MM-DDTHH:mm')}
        		onBlur={handleBlur} 
        		className={classes.textField}
        		InputLabelProps={{
          			shrink: true,
        		}}
      		/>
		</Grid>
		<Grid item sm={6}>
			<FormControlLabel
          		control={
            		<Checkbox
            			checked={(todo.favorite)?true:false}
              			onChange={handleChange}
              			onBlur={handleBlur} 
              			name="favorite"
              			color="primary"
            		/>
          		}
          		label="Favorite"
        	/>
		</Grid>
		<Grid item sm={12}>			
			{(isUploading)?<Spinner />:<FormControl className={classes.textField} fullWidth>
				<InputLabel shrink >
            		Photo
          		</InputLabel>
          		<label htmlFor={'uploadPhotoEdit'}>
          			{(todo.photo)?
          				<img src={DOMAIN_CONTENT +"content/md"+ todo.photo} alt={todo.name} style={{width: "100%"}}/>
						: <FontAwesomeIcon icon="image" color='#3B5998' size='10x' />
					}
				</label>
				<input type='file' id={'uploadPhotoEdit'} onChange={onChangeUpload} style={{visibility: "hidden"}}/> 

			</FormControl>}	
			<Button variant="contained" color="default" className={classes.button} onClick={onClickHandleRotate}>
        		Rotate
      		</Button>		
		</Grid>
	</Grid>
}

export default withStyles(styles)(TodoEdit);