import { combineReducers } from 'redux'
import auth from './auth'
import alert from './alert'

import todo from './todo'


const travelApp = combineReducers({
	alert,
	auth,
	todo,
})
 
export default travelApp