import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './Spinner.css'

const Spinner = (props) => {

	return <div className="spinnerContainer">
		<div className="spinner fadein" >
    		<FontAwesomeIcon icon="bowling-ball" size='5x' color='#3B5998' />
  		</div>
  	</div>

}
  

export default Spinner;