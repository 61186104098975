import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles'

import MenuContainer from './menuContainer/MenuContainer';

const styles = theme => ({
	container: {
		display: 'flex',
		height: '100vh',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.default,
	},
  	main: {
  		height: '100vh',
  		overflow: 'auto',
     	width: '100%',
     	padding: theme.spacing.unit
  	},

});

const Page3Col = (props) => {
	const {classes} = props
	return <div>
		<MenuContainer onSearchChange={props.onSearchChange} />
		<div className={classes.container}>
			<main className={classnames(classes.main)}>
				{props.main}			
			</main>
		</div>
	</div>    
}

export default withStyles(styles)(Page3Col)