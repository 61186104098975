import {
  TODO_LOAD_REQUEST,
  TODO_LOAD_SUCCESS,
  TODO_LOAD_FAILURE,
  TODO_ADD_REQUEST,
  TODO_ADD_SUCCESS,
  TODO_ADD_FAILURE,
  TODO_SAVE_REQUEST,
  TODO_SAVE_SUCCESS,
  TODO_SAVE_FAILURE,
  TODO_SAVE_FIELD_REQUEST,
  TODO_SAVE_FIELD_SUCCESS,
  TODO_SAVE_FIELD_FAILURE,
  TODO_UPLOAD_PHOTO_REQUEST,
  TODO_UPLOAD_PHOTO_SUCCESS,
  TODO_UPLOAD_PHOTO_FAILURE,
  TODO_DELETE_REQUEST,
  TODO_DELETE_SUCCESS,
  TODO_DELETE_FAILURE,
} from '../actions/todo'

import update from 'immutability-helper';

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const todo = (state = [], action) => {
	let newState = ""
	let rows = ""
	switch (action.type) {
		case TODO_LOAD_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case TODO_LOAD_SUCCESS:			
			newState = Object.assign( {}, state )
			if( action.rows ){				 
				newState = update( newState, {
					isFetching: {$set: false},
					list: {$set: action.rows},
				});
			}
			return newState 

		case TODO_LOAD_FAILURE:
			return {};

		case TODO_ADD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TODO_ADD_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows ){
				newState = update( state, {
					isSaving: {$set: false},
					list: {$set: state.list || [] },
				})
				newState = update( newState, {
					isSaving: {$set: false},
					list:  {$push: [rows]},
				});
			}
			return newState 

		case TODO_ADD_FAILURE:
			return {};

		
		case TODO_SAVE_REQUEST:
		case TODO_SAVE_FIELD_REQUEST:
		case TODO_UPLOAD_PHOTO_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},	
				uploadingListId: {$set: state.uploadingListId || [] },
			})
			if( action.type === "TODO_UPLOAD_PHOTO_REQUEST"){
				newState = update( newState, {
					isUploading: {$set: true},
					uploadingListId: {$push: [action.task.todoId]},
				})
			}
			return newState 
		case TODO_SAVE_SUCCESS:
		case TODO_SAVE_FIELD_SUCCESS:
		case TODO_UPLOAD_PHOTO_SUCCESS:
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$apply: function(todoList){
					return todoList.map( t => {
						if(t.id === rows.id){
							if( action.type === "TODO_UPLOAD_PHOTO_SUCCESS"){
								rows.photo = rows.photo +"?r="+ parseInt(Math.random()*100,10)

							}
							return rows
						}
						return t
					})					
				}},
			});
			if( action.type === "TODO_UPLOAD_PHOTO_SUCCESS"){
				newState = update( newState, {
					isUploading: {$set: false}
				})
				const index = newState.uploadingListId.indexOf(action.todoId);
				if (index >= 0) {
  					newState = update(newState, { uploadingListId: { $splice: [[index, 1]] } });
				}
			}
			return newState 
		
		case TODO_SAVE_FAILURE:
		case TODO_SAVE_FIELD_FAILURE:
		case TODO_UPLOAD_PHOTO_FAILURE:		
			return {};

		case TODO_DELETE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TODO_DELETE_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows.kaizenDay ){
				let row = rows.kaizenDay
				newState = update( state, {
					isSaving: {$set: false},
					list: {$uset: row.id},
				});
			}
			return newState 

		case TODO_DELETE_FAILURE:
			return {};

		default:
			return state
  }
}

export default todo