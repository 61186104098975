import React, {Component} from 'react';

import classnames from 'classnames';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DoneIcon from '@material-ui/icons/Done';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';


import { withStyles } from '@material-ui/core/styles'



import TodoEditContainer from '../../todoEditContainer/TodoEditContainer'
import TodoDeleteContainer from '../../todoDeleteContainer/TodoDeleteContainer'
import Spinner from '../../../Spinner'


import {DOMAIN_CONTENT} from '../../../../constants/constants';
import { todoType} from '../../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var moment = require('moment');

const styles = theme => ({
  container:{
    flexGrow: 1,
    flexBasis: 1,
    width: '100%',    
  },
  card: {
    maxWidth: 400,
    width: '100%',
    marginBottom: theme.spacing.unit * 2
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: green[500],
  },
  doneActive: {
    color: 'blue'
  },
  favoriteActive: {
    color: 'red'
  },
  uploadButton:{
    width: "100%",
    textAlign: "center",
  }
});

class Todo extends Component {    	
	
  	state = { 
   	  anchorEl: null,
   	  expanded: false,
   	  editOpen: false,
  	}

  	handleExpandClick = () => {
   	  this.setState(state => ({ expanded: !state.expanded }));
  	}

  	handleMenuClick = event => {
   	  this.setState({ anchorEl: event.currentTarget });
  	}

  	handleMenuClose = () => {
   	  this.setState({ anchorEl: null });
  	}

  	handleClickEditOpen = () => {
   	  this.setState({ editOpen: true });
  	}

  	handleEditClose = () => {
      this.handleMenuClose()
   	  this.setState({ editOpen: false });
  	}

	render() {
		const { todo, onClickDone, onClickFavorite,onChangeUpload, onClickHandleRotate, isUploading, classes, ...other } = this.props
		const { anchorEl } = this.state
		
      if( !todo ) return false

    	let type = ""
    	todoType.filter( t => t.value === todo.type ).map( t => {
      	type = t
      	return true
    	})
 

      let media = <div>
        <label htmlFor={'uploadPhoto_'+ todo.id} className={classes.uploadButton} >
          <FontAwesomeIcon icon="image" color='#3B5998' size='10x' />
        </label>
        <input type='file' id={'uploadPhoto_'+ todo.id} onChange={this.props.onChangeUpload} style={{visibility: "hidden"}}/> 
      </div>

      
      if( todo.photo){
  		  media = <CardMedia
            className={classes.media}
            image={DOMAIN_CONTENT+ "content/md"+ todo.photo}
            title={todo.name}
          />
      }

      if( isUploading){
         media = <CardContent>
            <Spinner />
         </CardContent>
      }


    let doneActive = (todo.done )?classes.doneActive:""
    let favoriteActive = (todo.favorite )?classes.favoriteActive:""
    let subheader = ""

    if( todo.done && todo.done_date && todo.done_date !== "0000-00-00 00:00:00" ){
      subheader = moment(todo.done_date).format("MMMM DD, YYYY")
    }
		return <div className={classes.container} ><Card className={classes.card}>        
        <CardHeader
          avatar={
            <Avatar aria-label={type.label} className={classes.avatar} 
              style={{backgroundColor: type.bg, color: type.color}}>
              <FontAwesomeIcon icon={type.icon} />
            </Avatar>
          }
          action={<div>
            <IconButton aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
          		id="simple-menu"
          		anchorEl={anchorEl}
          		open={Boolean(anchorEl)}
          		onClose={this.handleMenuClose}
        		>
        			<MenuItem onClick={this.handleClickEditOpen}>Edit</MenuItem>
	        		<TodoDeleteContainer handleClose={this.handleMenuClose} id={todo.id} view="menuItem"/>          	
        		</Menu>
        		</div>
          }
          title={todo.name}
          subheader={subheader}
        />
        {media}
        <CardContent>
          <Typography component="p" variant="body1">
            {todo.description}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions} disableActionSpacing>
          <IconButton aria-label="Add to favorites" className={favoriteActive} onClick={onClickFavorite}>
            <FavoriteIcon />
          </IconButton>          
          
          <IconButton aria-label="Done" className={doneActive} onClick={onClickDone} >
            <DoneIcon />
          </IconButton>
          
          
          
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: this.state.expanded,
            })}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            
          </CardContent>
        </Collapse>
      </Card>
      <Dialog
          open={this.state.editOpen}
          onClose={this.handleEditClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
        >
        <TodoEditContainer {...other} todo={todo} 
          onChangeUpload={onChangeUpload}
          onClickHandleRotate={onClickHandleRotate}
          isUploading={isUploading}
          handleClose={this.handleEditClose}
           />
      </Dialog>
	</div>
	}
}

export default withStyles(styles)(Todo);