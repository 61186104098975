import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { todoActions } from '../../../actions'


import TodoEditDialog from './todoEditDialog/TodoEditDialog'

class TodoEditContainer extends Component { 
	
	constructor(props) {
		super(props)

		this.state = {
			todo: '',	
		}
	}

	componentWillMount(){
		const {todo} =	this.props
		if( !todo ) return false

		this.setState({
			todo: todo
		})

	}

	componentWillReceiveProps( nextProps ){
		const { todo} =	nextProps
		if( !todo ) return false

		this.setState({
			todo: todo
		})
	}

	handleChange = ( event ) => {
        const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			todo: { [name]: {$set: value} },
		});

		this.setState( newState );
    }

    handleBlur = ( event ) => {
    	const {dispatch} = this.props
		const {todo} = this.state

        const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		dispatch( todoActions.saveField( todo.id, name, value ) )
    }
    /*
    handleSubmit = ( event ) => {
    	const {dispatch} = this.props
		const {todo} = this.state

        dispatch( todoActions.save( todo ) )
    }*/

	render(){
		const {todo} = this.state
		if( !todo ) return false
		return  <TodoEditDialog {...this.props}
			handleClose={this.props.handleClose}
			handleChange={this.handleChange}
			handleBlur={this.handleBlur}
			//handleSubmit={this.handleSubmit}
			onClickHandleRotate={this.props.onClickHandleRotate}
			onChangeUpload={this.props.onChangeUpload}			
			todo={todo} />
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTodo: state.todo, 
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoEditContainer)
