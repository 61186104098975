import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { Link } from 'react-router-dom';
import { fade } from '@material-ui/core/styles/colorManipulator';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  	root: {
   	flexGrow: 1,
  	},
  	flex: {
   	flexGrow: 1,
  	},
  	menuButton: {
   	marginLeft: -12,
   	marginRight: 20,
  	},
  	search: {
   	position: 'relative',
   	borderRadius: theme.shape.borderRadius,
   	backgroundColor: fade(theme.palette.common.white, 0.15),
   	'&:hover': {
      		backgroundColor: fade(theme.palette.common.white, 0.25),
    	},
   	marginLeft: 0,
   	width: '100%',
   	[theme.breakpoints.up('sm')]: {
      	marginLeft: theme.spacing.unit,
      	width: 'auto',
    	},
  	},
  	searchIcon: {
		width: theme.spacing.unit * 9,
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
  	},
  	inputRoot: {
   	color: 'inherit',
   	width: '100%',
  	},
  	inputInput: {
		paddingTop: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingBottom: theme.spacing.unit,
		paddingLeft: theme.spacing.unit * 10,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
		width: 120,
		'&:focus': {
		  width: 200,
		},
		},
  	},
})
/*<Button color="inherit"><NavLink to="/topic">Temas</NavLink></Button> */
const Menu = (props) => {
  	const { classes } = props;

	return <AppBar position="sticky">
		<Toolbar>

			<Typography variant="h6" color="inherit" className={props.classes.flex}
      component={Link} to="/" >
			    旅行
			</Typography>       
	      <div className={classes.grow} />
	        	<div className={classes.search}>
	            <div className={classes.searchIcon}>
	             	<SearchIcon />
	            </div>
	            <InputBase
	              	placeholder="Search…"
	              	onChange={props.onSearchChange}
	              	classes={{
	               	root: classes.inputRoot,
	                	input: classes.inputInput,
	              	}}
	            />
	      </div>
		</Toolbar>
	</AppBar>
}

export default withStyles(styles)(Menu);