import {fetchApi} from '../helpers';

export const todoService = {
	load,
    loadById,
	add,
    save,
    saveField,
    uploadPhoto,
    fixOrientationPhoto,
    _delete,
};

function load() {
    const res  = fetchApi('api/todoLoad', {
    }) 

    return res
}

function loadById( id ) {
    const res  = fetchApi('api/todoLoadById', {
        id: id,
    }) 

    return res
}

function add( todo ) {
    const res  = fetchApi('api/todoAdd', {
        todo: todo,
    }) 

    return res
}

function save(todo) {
    const res  = fetchApi('api/todoSave', {
        todo: todo,
    }) 

    return res
}

function saveField(id,name,value) {
    const res  = fetchApi('api/todoSaveField', {
        id: id,
        name: name,
        value: value,
    }) 

    return res
}

function uploadPhoto( formData ) {
    const res  = fetchApi('api/todoUploadPhoto', formData,'file') 

    return res
}

function fixOrientationPhoto( src ) {
    const res  = fetchApi('api/todofixOrientationPhoto', src ) 

    return res
}

function _delete(id) {
    const res  = fetchApi('api/todoDelete', {
        id: id,
    }) 

    return res
}