
import React, {Component} from 'react';
import { connect } from 'react-redux'

import { todoActions } from '../../../actions'
import { todoService } from '../../../services'

import Todo from './todo/Todo';


class TodoContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			todo: "",
			uploading: false
		}
	}

	componentWillMount(){
		
		this.componentWillReceiveProps(this.props)	
  	}

	componentWillReceiveProps( nextProps ){
		let isUploadingThis = false
		if( nextProps.storeTodo.isUploading && nextProps.storeTodo.uploadingListId){
			if( nextProps.storeTodo.uploadingListId.includes( this.state.todo.id ) ){
				isUploadingThis = true
			}
		}
		this.setState({
			todo: nextProps.todo,
			isUploading: isUploadingThis
		})
	}
	
	onChangeUpload = e => {
		const {dispatch} = this.props

    	const files = Array.from(e.target.files)

    	let data ={
    		files: files,
    		userId: 1,
    		todoId: this.state.todo.id
    	}

	    dispatch( todoActions.uploadPhoto( data ) )    	
  	}

	onClickHandleRotate = () => {
		const {todo} = this.state

	    todoService.fixOrientationPhoto( todo.src )
	}

  	onClickDone = e => {
		const {todo} = this.state
		const {dispatch} = this.props

	    dispatch( todoActions.saveField( todo.id, "done", !todo.done ) )    	
  	}

  	onClickFavorite = e => {
		const {todo} = this.state

		const {dispatch} = this.props

    	dispatch( todoActions.saveField( todo.id, "favorite", !todo.favorite ) )
  	}

	render(){
		const {todo,isUploading} = this.state
		if ( !todo ) return false

		if( this.props.view && this.props.view === "xs" ){
			return false
		}else{
			return <Todo {...this.props} 
				todo={todo}
				actions={this.props.actions}
				onChangeUpload={this.onChangeUpload}
				onClickHandleRotate={this.onClickHandleRotate}
				onClickDone={this.onClickDone}
				onClickFavorite={this.onClickFavorite}
				isUploading={isUploading}
				page={this.props.page} />	
		}
		
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTodo: state.todo,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoContainer);
