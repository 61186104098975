import React, {Component} from 'react';

import classNames from 'classnames';

import { NavLink } from 'react-router-dom'
import {   Link } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircle from '@material-ui/icons/AccountCircle';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth';

import compose from 'recompose/compose';

import { todoType} from '../../../../constants';

import PhotoGridContainer from '../../photoGridContainer/PhotoGridContainer';

const styles = theme => ({
  	root: {
    	display: 'flex',
   		flexWrap: 'wrap',
   		justifyContent: 'space-around',
   		overflow: 'hidden',
   		backgroundColor: theme.palette.background.paper,
  	},
  	appBar: {
    	zIndex: theme.zIndex.drawer + 1,
    	transition: theme.transitions.create(['width', 'margin'], {
      		easing: theme.transitions.easing.sharp,
      		duration: theme.transitions.duration.leavingScreen,
    	}),
  	},
  	appBarShift: {
    	marginLeft: drawerWidth,
    	width: `calc(100% - ${drawerWidth}px)`,
    	transition: theme.transitions.create(['width', 'margin'], {
      		easing: theme.transitions.easing.sharp,
      		duration: theme.transitions.duration.enteringScreen,
    	}),
  	},
  	menuButton: {
    	marginLeft: 12,
    	marginRight: 36,
  	},
  	hide: {
    	display: 'none',
  	},
  	drawer: {
    	width: drawerWidth,
    	flexShrink: 0,
    	whiteSpace: 'nowrap',
  	},
  	drawerOpen: {
    	width: drawerWidth,
    	transition: theme.transitions.create('width', {
      		easing: theme.transitions.easing.sharp,
      		duration: theme.transitions.duration.enteringScreen,
    	}),
  	},
  	drawerClose: {
    	transition: theme.transitions.create('width', {
      		easing: theme.transitions.easing.sharp,
      		duration: theme.transitions.duration.leavingScreen,
    	}),
    	overflowX: 'hidden',
    	width: theme.spacing.unit * 7 + 1,
    	[theme.breakpoints.up('sm')]: {
      		width: theme.spacing.unit * 9 + 1,
    	},
  	},
  	toolbar: {
    	display: 'flex',
    	alignItems: 'center',
    	justifyContent: 'flex-end',
    	padding: '0 8px',
    	...theme.mixins.toolbar,
  	},
  	content: {
    	flexGrow: 1,
    	flexBasis: 1,
    	padding: theme.spacing.unit * 3,
  	},
    buttonMenu:{
      float: "right"
    },
    grow: {
      flexGrow: 1,
    },
})

const drawerWidth = 240;

class Cover extends Component {    	
	state = {
    	open: false,
  	};

  	handleDrawerOpen = () => {
    	this.setState({ open: true });
  	};

  	handleDrawerClose = () => {
    	this.setState({ open: false });
  	};

	render() {
		const {todoList, width, todoTypeValue, classes} = this.props
		if( !todoList ) return false

		let cols = 2
    let baseWidth  =  '200'
    let iconSize = '5x'
		switch( width ){
			case 'xl':
				cols = 8
        baseWidth  =  '200'
        iconSize  =  '5x'
				break
			case 'lg':
				cols = 6
        baseWidth  =  '200'
        iconSize  =  '5x'
				break
			case 'sm':
				cols = 4
        baseWidth  =  '200'
        iconSize  =  '5x'
				break
			case 'xs':
			default:
				cols = 2
        baseWidth  =  '100'
        iconSize  =  '2x'
				break
		}

		return  <div className={classes.root}>
			<CssBaseline />
			<AppBar
	        	position="fixed"
          		className={classNames(classes.appBar, {
            		[classes.appBarShift]: this.state.open,
          		})}>
          		<Toolbar disableGutters={!this.state.open}>
            		<IconButton
              			color="inherit"
              			aria-label="Open Menu"
              			onClick={this.handleDrawerOpen}
              			className={classNames(classes.menuButton, {
                			[classes.hide]: this.state.open,
              		})}
            		>
              			<MenuIcon />
            		</IconButton>
                <Typography variant="h6" color="inherit" noWrap
                  component={Link} to="/admin/" className={classes.grow}>                
              			旅行
            		</Typography>

                <div className={classes.buttonMenu}>
                  <IconButton component={Link} to="/admin/"
                    color="inherit"  >
                    <AccountCircle />
                  </IconButton>
                </div>

          		</Toolbar>
        	</AppBar>
        	<Drawer
          		variant="permanent"
          		className={classNames(classes.drawer, {
            		[classes.drawerOpen]: this.state.open,
            		[classes.drawerClose]: !this.state.open,
          		})}
          		classes={{
            		paper: classNames({
              			[classes.drawerOpen]: this.state.open,
	              		[classes.drawerClose]: !this.state.open,
    	        	}),
          		}}
        	  open={this.state.open}
        	>
          		<div className={classes.toolbar}>
            		<IconButton onClick={this.handleDrawerClose}>
              			<ChevronLeftIcon />
            		</IconButton>
          		</div>
          		<Divider />
          		<List>
          			<ListItem button 
          				key="all" 
          				component={NavLink} 
          				to={"/"} >
            			<ListItemIcon>
            				<FontAwesomeIcon icon="asterisk" size="lg"/>
						</ListItemIcon>
            			<ListItemText primary="All" />
          			</ListItem>
            		{todoType.map((type, index) => (
              			<ListItem button 
              				key={type.value} 
              				component={NavLink} 
              				selected={todoTypeValue === type.value}
              				to={"/t/"+ type.value} >
                			<ListItemIcon>
                				<FontAwesomeIcon icon={type.icon} size="lg"/>
							</ListItemIcon>
                			<ListItemText primary={type.label} />
              			</ListItem>
            		))}
          		</List>
          		<Divider />
          	</Drawer>
          	<main className={classes.content}>
          		<div className={classes.toolbar} />
				      <PhotoGridContainer list={todoList} cols={cols} baseWidth={baseWidth} iconSize={iconSize} />
            </main>
    	</div>
	}
}

export default compose(
  withStyles(styles),
  withWidth(),
)(Cover);