export const todoType = [
	{
		value: 'country',
		label: 'Country',
		icon: 'globe-asia',
		bg: 'blue',
		color: '',
	},
	{
		value: 'region',
		label: 'Region',
		icon: 'globe',
		bg: 'blue',
		color: '',
	},
	{
		value: 'city',
		label: 'City',
		icon: 'city',
		bg: 'blue',
		color: '',
	},
	{
		value: 'district',
		label: 'District',
		icon: 'gopuram',
		bg: 'yellow',
		color: 'black',
	},
	{
		value: 'food',
		label: 'Food',
		icon: 'apple-alt',
		bg: 'red',
		color: '',
	},
	{
		value: 'nature',
		label: 'Nature',
		icon: 'mountain',
		bg: 'green',
		color: '',
	},
	{
		value: 'architecture',
		label: 'Architecture',
		icon: 'vihara',
		bg: 'yellow',
		color: 'black',
	},
	{
		value: 'museum',
		label: 'Museum',
		icon: 'university',
		bg: 'yellow',
		color: 'black',
	},
	{
		value: 'restaurant',
		label: 'Restaurant',
		icon: 'utensils',
		bg: 'red',
		color: '',
	},
	{
		value: 'history',
		label: 'History',
		icon: 'archway',
		bg: 'yellow',
		color: 'black',
	},
	{
		value: 'garden',
		label: 'Garden',
		icon: 'tree',
		bg: 'green',
		color: '',
	},
	{
		value: 'plant',
		label: 'Plants',
		icon: 'leaf',
		bg: 'green',
		color: '',
	},
	{
		value: 'animal',
		label: 'Animal',
		icon: 'paw',
		bg: 'green',
		color: '',
	},

]