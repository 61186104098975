import { authService } from '../services';
import { alertActions } from './';
import Auth from '../modules/Auth';
import { history } from '../helpers';



export const authActions = {
    login,
    logout,
}

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));        
        authService.login(email, password)
            .then(
                response => { 
                    dispatch(success(response));
                    Auth.authenticateUser(response.token);
                    dispatch( alertActions.success(response.message) );
                    history.push('/admin/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: AUTH_LOGIN_REQUEST, user } }
    function success(user) { return { type: AUTH_LOGIN_SUCCESS, user } }
    function failure(error) { return { type: AUTH_LOGIN_FAILURE, error } }
}

function logout() {
    //userService.logout();
    return { type: AUTH_LOGOUT };
}